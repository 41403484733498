import { shapes } from '@/utils/figures'
import { cloneDeep } from 'lodash'
import {
  getDistance,
  getMax,
  getMaxArray, getMaxWithCorrection,
  getMin, getMinWithCorrection,
  normalizedDist
} from './math'
import { Point } from '../class/Point'
import { Vector } from '../class/Vector'

export const defaultSvgParams = {
  width: 740, // 700
  height: 540, // 500
  baseHeight: 4,
  baseWidth: 6,
  arrowsOffset: 50,
  serif: 15,
  biasArrowsOffset: 10, // 20
  biasTextOffset: 20,
  biasSideTextOffset: 10,
  zoneLetterOffset: 10
  // navScale: 0.1765
}

export const zoneLetters = {
  c: 'C',
  d: 'D',
  e: 'E'
}

export const MAX_SIDES_QUANTITY_IN_XSHAPE = 12
const X_SHAPE = 'x-shaped'

const cx = defaultSvgParams.width / 2
const cy = defaultSvgParams.height / 2

const sumArr = arr => arr.reduce((acc, val) => acc + val, 0)

const getLess = values => Math.min(...values)

const getScale = (widthParams, heightParams) => {
  const scaleHeight = (defaultSvgParams.baseHeight * 100) / sumArr(heightParams)
  const scaleWidth = (defaultSvgParams.baseWidth * 100) / sumArr(widthParams)
  return getLess([scaleHeight, scaleWidth])
}

export function rollSizeCorrection(value, rollsWidthOptions, delimiter) {
  const scaledValue = (value / delimiter) * 1000

  const overlapForWall = 60
  let { width, overlap } = rollsWidthOptions
  width = width * 1000
  const distance = width - overlap
  let resultSize = 0
  let rollsCount = 0

  while (resultSize < scaledValue) {
    let lengthRolls = width
    if (resultSize === 0) {
      lengthRolls -= overlapForWall
    }
    lengthRolls -= overlap
    resultSize += lengthRolls
    rollsCount++
  }
  const firstRoll = (width - overlapForWall - overlap) / 1000

  return parseFloat((firstRoll + (distance / 1000) * (rollsCount - 1)).toFixed(2))
}

const getScaledParams = (params, scale) => Object.entries(params).map(([_, val]) => val * scale)

const getTotalWidthAndHeight = (widthParams, heightParams, scale) => {
  return {
    width: (sumArr(widthParams) * scale) / 2,
    height: (sumArr(heightParams) * scale) / 2
  }
}

const getStartPoint = (width, height) => {
  return {
    x1: cx - width,
    y1: cy - height
  }
}

const getMinSides = (sides, buildingHeight, isRectangle) => {
  const sidesLikeArr = Object.entries(sides)
  const [firstItem] = sidesLikeArr
  const [lastItem] = sidesLikeArr.slice(-1)
  return Object.fromEntries(
    sidesLikeArr.map((currentItem, index) => {
      const [key, val] = currentItem
      if (sidesLikeArr.length === 1) {
        return currentItem
      } else {
        const isLastIndex = index === sidesLikeArr.length - 1
        const isFirstIndex = index === 0
        const [, prevItem] = isFirstIndex ? lastItem : sidesLikeArr[index - 1]
        const [, nextItem] = isLastIndex ? firstItem : sidesLikeArr[index + 1]
        return isRectangle
          ? [key, getMin(val, prevItem, nextItem)]
          : [key, getMin(val, prevItem, nextItem, buildingHeight * 2)]
      }
    })
  )
}

export const getScaledBiases = (sides, buildingHeight, scale, isRectangle = false, coordinates = null, rollWidthOptions) => {
  const calculatedSides = getMinSides(sides, buildingHeight, isRectangle)
  const delimiter = isRectangle ? 5 : 4
  return {
    biasSide: getScaledSides(calculatedSides, scale, 10, coordinates, rollWidthOptions, sides),
    biasAngle: getScaledSides(calculatedSides, scale, delimiter, coordinates, rollWidthOptions, sides)
  }
}

export const getSideRatio = (start, end, side, rollWidth) => {
  return ((end - start) * rollWidth) / side
}

const getScaledSides = (sides, scale, delimiter, coordinates, rollWidthOptions, realSides) => {
  const quantityAngleZones = getQuantityAngleZones(coordinates)

  return Object.fromEntries(
    Object.entries(sides).map(([key, val], i, array) => {
      let valuePx = 0
      let correction = null
      let sideLength = realSides[i]
      let widthRolls
      const nextIndex = coordinates[i + 1] ? i + 1 : 0

      if (rollWidthOptions) {
        widthRolls = rollSizeCorrection(val, rollWidthOptions, delimiter)
        if (delimiter === 4 || delimiter === 5) {
          const nextIndexForValue = array[i + 1] ? i + 1 : 0
          const nextValue = array[nextIndexForValue][1]
          const widthRollsForNextValue = rollSizeCorrection(nextValue, rollWidthOptions, delimiter)

          if (quantityAngleZones[i] === 2 && (widthRolls + widthRollsForNextValue) > realSides[i]) {
            widthRolls = sideLength / 2
            correction = sideLength / 2
          }
        }

        valuePx = coordinates[i].y === coordinates[nextIndex].y
          ? getSideRatio(coordinates[i].x, coordinates[nextIndex].x, realSides[i], widthRolls)
          : getSideRatio(coordinates[i].y, coordinates[nextIndex].y, realSides[i], widthRolls)
      }
      const value = rollWidthOptions ? widthRolls : (val / delimiter) * scale
      return [
        key,
        [
          value,
          { minSide: val, delimiter, ratio: Math.abs(valuePx), correction, realSide: realSides[i] },
          { start: coordinates[i], end: coordinates[nextIndex] }
        ]
      ]
    })
  )
}

export function getQuantityAngleZones(coords) {
  const n = coords.length
  const quantityAngleZones = []

  for (let i = 0; i < n; i++) {
    const indexPrev = i === 0 ? n - 1 : i - 1
    const indexNext = i === n - 1 ? 0 : i + 1
    const indexFourth = indexNext === n - 1 ? 0 : indexNext + 1

    const current = new Point(coords[i])
    const prev = new Point(coords[indexPrev])
    const next = new Point(coords[indexNext])
    const fourth = new Point(coords[indexFourth])

    const vectorPrev = new Vector(prev, current)
    const vectorNext = new Vector(current, next)
    const vectorFourth = new Vector(next, fourth)

    Vector.ifRightCornerVectors(vectorPrev, vectorNext) ? quantityAngleZones.push(1) : quantityAngleZones.push(0)
    quantityAngleZones[i] = Vector.ifRightCornerVectors(vectorNext, vectorFourth)
      ? quantityAngleZones[i] + 1
      : quantityAngleZones[i] + 0
  }

  return quantityAngleZones
}

const getRectangle = params => {
  const widthParams = [params.b]
  const heightParams = [params.a]
  const scale = getScale(widthParams, heightParams)

  const { width, height } = getTotalWidthAndHeight(widthParams, heightParams, scale)

  const { x1, y1 } = getStartPoint(width, height)
  const [scaledSideA, scaledSideB] = getScaledParams(params, scale)
  const y2 = y1 + scaledSideA
  const x2 = x1 + scaledSideB

  const collinearCoordinates = [
    { x: x1, y: y1 },
    { x: x2, y: y1 },
    { x: x2, y: y2 },
    { x: x1, y: y2 }]

  const coordinates = removeCollinearCoords(collinearCoordinates)

  const points = { x1, x2, y1, y2 }
  return { points, scale, coordinates }
}

const getCorner = params => {
  const widthParams = [params.c, params.d]
  const heightParams = [params.a, params.b]

  const scale = getScale(widthParams, heightParams)

  const { width, height } = getTotalWidthAndHeight(widthParams, heightParams, scale)
  const { x1, y1 } = getStartPoint(width, height)
  const [scaledSideA, scaledSideB, scaledSideC, scaledSideD] = getScaledParams(params, scale)
  const y2 = y1 + scaledSideA
  const y3 = y2 + scaledSideB
  const x2 = x1 + scaledSideC
  const x3 = x2 + scaledSideD

  const collinearCoordinates = [
    { x: x1, y: y1 },
    { x: x2, y: y1 },
    { x: x3, y: y1 },
    { x: x3, y: y2 },
    { x: x2, y: y2 },
    { x: x2, y: y3 },
    { x: x1, y: y3 }
  ]

  const coordinates = removeCollinearCoords(collinearCoordinates)

  const points = { x1, x2, x3, y1, y2, y3 }
  return { points, scale, coordinates }
}

const getTShaped = params => {
  const widthParams = [params.c, params.d, params.e]
  const heightParams = [params.a, params.b]
  const scale = getScale(widthParams, heightParams)
  const { width, height } = getTotalWidthAndHeight(widthParams, heightParams, scale)
  const { x1, y1 } = getStartPoint(width, height)
  const [scaledSideA, scaledSideB, scaledSideC, scaledSideD, scaledSideE] = getScaledParams(
    params,
    scale
  )
  const y2 = y1 + scaledSideA
  const y3 = y2 + scaledSideB
  const x2 = x1 + scaledSideC
  const x3 = x2 + scaledSideD
  const x4 = x3 + scaledSideE

  const collinearCoordinates = [
    { x: x1, y: y1 },
    { x: x2, y: y1 },
    { x: x3, y: y1 },
    { x: x4, y: y1 },
    { x: x4, y: y2 },
    { x: x3, y: y2 },
    { x: x3, y: y3 },
    { x: x2, y: y3 },
    { x: x2, y: y2 },
    { x: x1, y: y2 }
  ]

  const coordinates = removeCollinearCoords(collinearCoordinates)

  const points = { x1, x2, x3, x4, y1, y2, y3 }
  return { points, scale, coordinates }
}

const getPShaped = params => {
  const widthParams = [params.d, params.e > params.f ? params.e : params.f]
  const heightParams = [params.a, params.b, params.c]
  const scale = getScale(widthParams, heightParams)
  const { width, height } = getTotalWidthAndHeight(widthParams, heightParams, scale)
  const { x1, y1 } = getStartPoint(width, height)
  const [scaledSideA, scaledSideB, scaledSideC, scaledSideD, scaledSideE, scaledSideF] =
    getScaledParams(params, scale)
  const y2 = y1 + scaledSideA
  const y3 = y2 + scaledSideB
  const y4 = y3 + scaledSideC
  const x2 = x1 + scaledSideD
  const x3 = x2 + scaledSideE
  const x4 = x2 + scaledSideF

  const collinearCoordinates = [
    { x: x1, y: y1 },
    { x: x2, y: y1 },
    { x: x3, y: y1 },
    { x: x4, y: y1 },
    { x: x4, y: y2 },
    { x: x3, y: y2 },
    { x: x2, y: y2 },
    { x: x2, y: y3 },
    { x: x3, y: y3 },
    { x: x3, y: y4 },
    { x: x2, y: y4 },
    { x: x1, y: y4 }
  ]

  const coordinates = removeCollinearCoords(collinearCoordinates)

  const points = { x1, x2, x3, x4, y1, y2, y3, y4 }
  return { points, scale, coordinates }
}

const getXShaped = params => {
  const widthParams = [
    params.d,
    params.c >= params.g ? params.c : params.g,
    params.e >= params.i ? params.e : params.i
  ]

  const heightParams = [params.a, params.b, params.f]

  const scale = getScale(widthParams, heightParams)
  const { width, height } = getTotalWidthAndHeight(widthParams, heightParams, scale)
  const { x1: x0, y1: y0 } = getStartPoint(width, height)
  const [
    scaledSideA,
    scaledSideB,
    scaledSideF,
    scaledSideC,
    scaledSideD,
    scaledSideE,
    scaledSideI,
    scaledSideG
  ] = getScaledParams(params, scale)

  const y1 = y0
  const y2 = y1 + scaledSideA
  const y3 = y2 + scaledSideB
  const y4 = y3 + scaledSideF

  const x1 = scaledSideC > scaledSideG ? x0 + (scaledSideC - scaledSideG) : x0
  const x2 = x1 + scaledSideG
  const x3 = x2 + scaledSideD
  const x4 = x3 + scaledSideI

  const x5 = x2 - scaledSideC
  const x6 = x3 + scaledSideE

  // получение всех координат
  const collinearCoordinates = [
    { x: x1, y: y1 },
    { x: x2, y: y1 },
    { x: x3, y: y1 },
    { x: x4, y: y1 },
    { x: x4, y: y2 },
    { x: x3, y: y2 },
    { x: x3, y: y3 },
    { x: x6, y: y3 },
    { x: x6, y: y4 },
    { x: x3, y: y4 },
    { x: x2, y: y4 },
    { x: x5, y: y4 },
    { x: x5, y: y3 },
    { x: x2, y: y3 },
    { x: x2, y: y2 },
    { x: x1, y: y2 },
    { x: x1, y: y1 }
  ]

  const coordinates = removeCollinearCoords(collinearCoordinates)

  const points = { x1, x2, x3, x4, x5, x6, y1, y2, y3, y4 }
  return { points, scale, coordinates }
}

const getFigure = {
  [shapes.rectangle]: params => getRectangle(params),
  [shapes.corner]: params => getCorner(params),
  [shapes['t-shaped']]: params => getTShaped(params),
  [shapes['p-shaped']]: params => getPShaped(params),
  [shapes['x-shaped']]: params => getXShaped(params)
}

export const getFigureDrawingParams = ({ form, params }) => getFigure[form](params)

export const drawSizeArrowVertical = (x, y1, y2, isSmall = false) => {
  const xOffset = isSmall ? 3 : 5
  const yOffset = isSmall ? 4 : 6
  return `M${x},${y1}
          V${y2}
          M${x - xOffset},${y2 - yOffset}
          L${x},${y2}
          M${x + xOffset},${y2 - yOffset}
          L${x},${y2}
          M${x - xOffset},${y1 + yOffset}
          L${x},${y1}
          M${x + xOffset},${y1 + yOffset}
          L${x},${y1}`
}

export const drawSizeArrowHorizontal = (x1, y, x2, isSmall = false) => {
  const xOffset = isSmall ? 4 : 6
  const yOffset = isSmall ? 3 : 5
  return `M${x1},${y}
          H${x2}
          M${x1 + xOffset},${y - yOffset}
          L${x1},${y}
          M${x1 + xOffset},${y + yOffset}
          L${x1},${y}
          M${x2 - xOffset},${y - yOffset}
          L${x2},${y}
          M${x2 - xOffset},${y + yOffset}
          L${x2},${y}`
}

export const drawArrowByCoordinate = (start, end, isSmall = false) => {
  const vector = new Vector(start, end)
  const sign = vector.getPositiveOffsetCoordinates(1)

  const offsetStart = getOffsetCoordinatesCurrent(sign.dx, sign.dy, sign.dy, -sign.dx, isSmall)
  const offsetEnd = getOffsetCoordinatesCurrent(sign.dx, -sign.dy, sign.dy, sign.dx, isSmall)

  const offsetStartNext = getOffsetCoordinatesNext(sign.dx, sign.dy, sign.dy, sign.dx, isSmall)
  const offsetEndNext = getOffsetCoordinatesNext(sign.dx, -sign.dy, sign.dy, -sign.dx, isSmall)

  return `M${start.x},${start.y}
          L${end.x},${end.y}
          M${start.x},${start.y}
          L${start.x + offsetStart.dx},${start.y + offsetStart.dy}
          M${start.x},${start.y}
          L${start.x + offsetEnd.dx},${start.y + offsetEnd.dy}
          M${end.x},${end.y}
          L${end.x + offsetStartNext.dx},${end.y + offsetStartNext.dy}
          M${end.x},${end.y}
          L${end.x + offsetEndNext.dx},${end.y + offsetEndNext.dy}
          `
}

export const drawZoneArrowVertical = (x, y1, y2, y3, y4) => {
  return `M${x} ${y1}
          V${y4}
          M${x - 3} ${y3 + 6}
          L${x + 3} ${y3 - 6}
          M${x - 3} ${y2 + 6}
          L${x + 3} ${y2 - 6}`
}

export const drawZoneArrowHorizontal = (x1, x2, x3, x4, y) => {
  return `M${x1} ${y}
          H${x4}
          M${x3 + 3} ${y - 6}
          L${x3 - 3} ${y + 6}
          M${x2 + 3} ${y - 6}
          L${x2 - 3} ${y + 6}`
}

export const drawSideVector = (point1, point2, offset) => {
  return `M${point1.x} ${point1.y}
          L${point2.x + offset.dx} ${point2.y + offset.dy}
          M${point1.x - 3} ${point1.y + 6}
          L${point1.x + 3} ${point1.y - 6}
          M${point2.x - 3} ${point2.y + 6}
          L${point2.x + 3} ${point2.y - 6}
          `
}

export const drawZoneArrow = (arrowStart1, arrowEnd1, arrowStart2, arrowEnd2) => {
  return `M${arrowStart1.x},${arrowStart1.y}
          L${arrowEnd1.x},${arrowEnd1.y}
          M${arrowStart2.x},${arrowStart2.y}
          L${arrowEnd2.x},${arrowEnd2.y}
          `
}

export const getRealBiases = (scale, scaledBiases) => {
  const { biasSide, biasAngle } = scaledBiases
  const realSides = Object.fromEntries(
    Object.entries(biasSide).map(([key, val]) => {
      return [key, parseFloat((val / scale).toFixed(2))]
    })
  )

  const realAngles = Object.fromEntries(
    Object.entries(biasAngle).map(([key, val]) => {
      return [key, parseFloat((val / scale).toFixed(2))]
    })
  )
  return { realSides, realAngles }
}

export const getShapePathByCoordinates = coords => {
  let path = 'M' + coords[0].x + ',' + coords[0].y + ' '
  for (let i = 1; i < coords.length; i++) {
    path += 'L' + coords[i].x + ',' + coords[i].y + ' '
  }

  path += 'Z'
  return path
}

// получение сторон от координат
export function getSides(coordinates, scale) {
  const sideLengths = []
  const n = coordinates.length
  for (let i = 0; i < n; i++) {
    const current = new Point(coordinates[i])
    const next = new Point(coordinates[i === n - 1 ? 0 : i + 1])

    let distance = getDistance(current.x, current.y, next.x, next.y)
    sideLengths.push(normalizedDist(distance, scale))
  }

  return sideLengths
}

// удаление коллинеарных координат
export function removeCollinearCoords(coordinates) {
  const n = coordinates.length
  const newCoordinates = []
  let prev = new Point(coordinates[0])

  for (let i = 0; i < coordinates.length; i++) {
    let current = new Point(coordinates[i])
    let next = new Point(coordinates[i === n - 1 ? 0 : i + 1])

    if (Point.areEqual(current, next) || Point.areCollinear(prev, current, next)) {
      continue
    }

    if (
      newCoordinates.length === 0 ||
      !Point.areEqual(current, newCoordinates[newCoordinates.length - 1])
    ) {
      newCoordinates.push(current)
    }

    prev = current
  }

  return newCoordinates
}

export function checkDistanceBetweenZeroAngleSides(sides) {
  if (Object.values(sides).length !== MAX_SIDES_QUANTITY_IN_XSHAPE) {
    return sides
  }

  const firstZeroAngleZoneIndex = 3
  const secondZeroAngleZoneIndex = 9

  const firstStartX = sides[firstZeroAngleZoneIndex][2].start.x
  const secondEndX = sides[secondZeroAngleZoneIndex][2].end.x

  const distancePerPx = firstStartX - secondEndX
  const distancePerMeters = ((distancePerPx * sides[firstZeroAngleZoneIndex][0]) / sides[firstZeroAngleZoneIndex][1].ratio).toFixed(1)

  if (distancePerMeters < sides[firstZeroAngleZoneIndex][0] + sides[secondZeroAngleZoneIndex][0]) {
    Object.values(sides).forEach((i, index, items) => {
      if (index === firstZeroAngleZoneIndex || index === secondZeroAngleZoneIndex) {
        items[index][0] = distancePerMeters / 2
        items[index][1].ratio = distancePerPx / 2
        items[index][1].half = true
      }
    })
  }
  return sides
}

export function checkZeroAngleSide(zeroAngleZoneIndex, sides, angleCross) {
  const quantitySides = Object.keys(sides).length
  const middleOfFirstSide = sides[0][2].end.x - ((sides[0][2].end.x - sides[0][2].start.x) / 2)

  const getInitialOffset = (index) => {
    return index !== 3
      ? sides[index][2].start.x + sides[index][1].ratio
      : sides[index][2].start.x - sides[index][1].ratio
  }

  const initialOffset = getInitialOffset(zeroAngleZoneIndex)
  let newOffsetPx

  const xEndOfSide = sides[zeroAngleZoneIndex][2].end.x
  const sideDOffset = sides[quantitySides - 1][2].start.x + sides[quantitySides - 1][1].ratio

  if (middleOfFirstSide === sides[zeroAngleZoneIndex][2].start.x && quantitySides === MAX_SIDES_QUANTITY_IN_XSHAPE) {
    Object.values(sides).forEach((i, index, items) => {
      if (index === zeroAngleZoneIndex || index === (zeroAngleZoneIndex - 1) || index === (zeroAngleZoneIndex + 1)) {
        items[index][0] = 0
        items[index][1].ratio = 0
      }
    })
  }

  if (initialOffset < angleCross[0].x && angleCross[0].x < xEndOfSide) {
    newOffsetPx = parseInt((sides[zeroAngleZoneIndex][2].start.x - angleCross[0].x).toFixed(1))
  } else if (initialOffset < angleCross[0].x && angleCross[0].x > xEndOfSide && initialOffset <= sideDOffset) {
    newOffsetPx = xEndOfSide - sideDOffset
  } else if (sides[zeroAngleZoneIndex][2].start.x + sides[zeroAngleZoneIndex][1].ratio > getInitialOffset(3)) {
    newOffsetPx = sides[zeroAngleZoneIndex][1].ratio - (initialOffset - getInitialOffset(3))
  }

  if (newOffsetPx) {
    sides[zeroAngleZoneIndex][0] = parseFloat(((sides[zeroAngleZoneIndex][0] * newOffsetPx) / sides[zeroAngleZoneIndex][1].ratio).toFixed(2))
    sides[zeroAngleZoneIndex][1].ratio = newOffsetPx
    sides[zeroAngleZoneIndex][1].half = true
  }

  return sides
}

// получение координаты для построения ширины зоны
export function getDashedCoordinates(initialCoords, scale, withRolls, angleCross, quantityAnglesZone) {
  let newCoords = cloneDeep(initialCoords)

  if (MAX_SIDES_QUANTITY_IN_XSHAPE === Object.keys(scale).length) {
    scale = checkDistanceBetweenZeroAngleSides(scale)
  } else if (withRolls && quantityAnglesZone.some(z => z === 0)) {
    const indexies = quantityAnglesZone.map((z, i) => z === 0 ? i : null).filter(item => item)
    indexies.forEach((i) => {
      scale = checkZeroAngleSide(i, scale, angleCross)
    })
  }

  for (let i = 0; i < newCoords.length; i++) {
    // для построения ширины зон смотрим на текущую координату и следующую
    let current = new Point(newCoords[i])
    let next = new Point(newCoords[i + 1] ? newCoords[i + 1] : newCoords[0])

    // получение вектора x и y
    let vector = new Vector(current, next)
    const offset = withRolls ? scale[i][1].ratio : scale[i][0]
    const rightOffset = vector.getClockWiseOffsetCoordinates(offset)

    newCoords[i] = current.plus(rightOffset)
    newCoords[i === newCoords.length - 1 ? 0 : i + 1] = next.plus(rightOffset)
  }
  return newCoords
}

export function getDashedLineGroup(initialCoords, biasAngle, cross, figure, sides) {
  if (cross.length === 4 && cross[0].x === cross[1].x && cross[0].y === cross[1].y) {
    return [ { x1: cross[0].x, x2: cross[3].x, y1: cross[0].y, y2: cross[3].y } ]
  }

  const newCoords = cloneDeep(initialCoords)
  const n = newCoords.length
  const quantityAngleZones = getQuantityAngleZones(initialCoords)
  const coord = []

  for (let i = 0; i < n; i++) {
    const prevIndex = newCoords[i - 1] ? i - 1 : n - 1
    const nextIndex = newCoords[i + 1] ? i + 1 : 0
    const overNextIndex = newCoords[nextIndex + 1] ? nextIndex + 1 : 0
    const firstCrossCoord = cross[i]
    const secondCrossCoord = cross[i + 1] ? cross[i + 1] : cross[0]
    let vector = new Vector(new Point(newCoords[i]), new Point(newCoords[nextIndex]))
    let nextVector = new Vector(new Point(newCoords[nextIndex]), new Point(newCoords[overNextIndex]))

    let isSidesWithCorrection

    if (cross[i]) {
      isSidesWithCorrection = biasAngle[overNextIndex].correction !== null || biasAngle[prevIndex].correction !== null
    }

    if (quantityAngleZones[i] === 2) {
      let path = { x1: firstCrossCoord.x, x2: secondCrossCoord.x, y1: firstCrossCoord.y, y2: secondCrossCoord.y }

      if ((Vector.isHorizontalLeft(vector) || Vector.isHorizontalRight(vector)) && isSidesWithCorrection) {
        if (Vector.isHorizontalRight(vector)) {
          const minY = firstCrossCoord.y < secondCrossCoord.y ? firstCrossCoord.y : secondCrossCoord.y
          path.y1 = minY
          path.y2 = minY
        } else if (Vector.isHorizontalLeft(vector)) {
          const maxY = firstCrossCoord.y > secondCrossCoord.y ? firstCrossCoord.y : secondCrossCoord.y
          path.y1 = maxY
          path.y2 = maxY
        }
        coord.push(path)
      } else if ((Vector.isVerticalDown(vector) || Vector.isVerticalUp(vector)) && isSidesWithCorrection) {
        if (Vector.isVerticalDown(vector)) {
          const minX = firstCrossCoord.x < secondCrossCoord.x ? firstCrossCoord.x : secondCrossCoord.x
          path.x1 = minX
          path.x2 = minX
        } else if (Vector.isVerticalUp(vector)) {
          const maxX = firstCrossCoord.x > secondCrossCoord.x ? firstCrossCoord.x : secondCrossCoord.x
          path.x1 = maxX
          path.x2 = maxX
        }
        coord.push(path)
      }
    } else if (quantityAngleZones[i] === 1) {
      const { x1, x2 } = coord[0]
      const middleOfFirstLine = x2 - ((x2 - x1) / 2)

      if (Vector.isHorizontalLeft(vector) && cross[i]) {
        let x2
        if (n === MAX_SIDES_QUANTITY_IN_XSHAPE) {
          x2 = parseInt((biasAngle[i][2].end.x - biasAngle[i + i][1].ratio).toFixed(0))
        } else {
          const nextSideOffsetX = (sides.d / 2) <= biasAngle[i + 1][0] ? cross[0].x : biasAngle[i][2].end.x - biasAngle[i + 1][1].ratio
          x2 = cross[0].x > biasAngle[i][2].end.x ? 0 : nextSideOffsetX
        }
        coord.push({ x1: firstCrossCoord.x, y1: firstCrossCoord.y, x2, y2: firstCrossCoord.y })
      }
      if (Vector.isHorizontalLeft(vector) && !cross[i]) {
        const { x, y } = cross[i + 1]
        if (n === MAX_SIDES_QUANTITY_IN_XSHAPE) {
          const x2 = coord[i - 1].x2
          coord.push({ x1: x, y1: y, x2, y2: y })
        } else {
          coord.push({ x1: x, y1: y, x2: defaultSvgParams.width, y2: y })
        }
      }
      if (Vector.isHorizontalRight(vector)) {
        if (cross[i + 1] && !cross[i]) {
          const { x, y } = cross[i + 1]
          let x2
          if (quantityAngleZones[i - 1] === 0 || figure === X_SHAPE) {
            x2 = biasAngle[i + 1][1].correction ? cross[i + 3].x : 0
          } else {
            x2 = coord[i - 1].x2 ?? 0
          }
          coord.push({ x1: x, y1: y, x2, y2: y })
        } else {
          const { x, y } = cross[i]
          let x2
          if (figure === X_SHAPE) {
            const sideDMiddle = parseInt((biasAngle[2][2].start.x - (biasAngle[2][1].ratio / biasAngle[2][0]).toFixed(0) / 2))

            if (sides.d < 2 && sideDMiddle < cross[i - 2].x) {
              x2 = biasAngle[i - 1][1]?.correction ? cross[i - 2].x : sideDMiddle
            } else {
              x2 = biasAngle[i - 1][1]?.correction ? cross[i - 2].x : defaultSvgParams.width
            }
          } else {
            x2 = cross[i - 2] ? cross[i - 2].x : defaultSvgParams.width
          }
          coord.push({ x1: x, y1: y, x2: x2, y2: y })
        }
      }
      if (Vector.isVerticalUp(vector)) {
        if (cross[i]) {
          let y2
          let x

          if (cross[0].x !== cross[1].x) {
            if (cross[i].x < cross[5].x) {
              x = parseInt((biasAngle[i][2].start.x - biasAngle[i][1].ratio / biasAngle[i][0]).toFixed(0))
              y2 = cross[5].y
            } else {
              y2 = defaultSvgParams.height
              x = cross[i].x
            }
            coord.push({ x1: x, y1: cross[i].y, x2: x, y2 })
          }
        } else {
          const nextCross = cross[i + 1]
          if (nextCross && biasAngle[i][1].correction) {
            const x = Math.min(nextCross.x, middleOfFirstLine)
            coord.push({ x1: x, y1: nextCross.y, x2: x, y2: coord[0].y1 })
          } else if (nextCross) {
            if (figure === X_SHAPE) {
              let y2
              let x

              if (nextCross.x < cross[0].x && sides.d < 2) {
                y2 = cross[0].y
                x = parseInt((biasAngle[i][2].start.x + ((biasAngle[3][2].start.x - biasAngle[i][2].start.x).toFixed(0)) / 2))
              } else {
                y2 = biasAngle[1][1].correction
                  ? cross[0].y > cross[1].y ? cross[1].y : cross[0].y
                  : cross[0].y > cross[2].y ? cross[0].y : cross[2].y
                x = nextCross.x
              }
              coord.push({ x1: x, y1: nextCross.y, x2: x, y2 })
            } else {
              const cases = nextCross.x < cross[0].x && nextCross.x < cross[1].x
              let y2
              if (Vector.isHorizontalLeft(nextVector) && !cases) {
                y2 = coord[coord.length - 1].y1
              } else {
                y2 = cases ? cross[0].y : cross[1].y
              }
              coord.push({ x1: nextCross.x, y1: nextCross.y, x2: nextCross.x, y2 })
            }
          }
        }
      }
      if (Vector.isVerticalDown(vector)) {
        const nextCross = cross[i + 1]
        if (nextCross) {
          coord.push({ x1: nextCross.x, y1: nextCross.y, x2: nextCross.x, y2: coord[0].y1 })
        } else if (cross[i]) {
          const x = cross[i].x
          let y2
          if (figure === X_SHAPE) {
            y2 = cross[i].x > cross[1].x
              ? cross[1].y
              : cross[1].y === cross[2].y ? coord[1].y1 : coord[2].y1
          } else {
            y2 = 0
          }
          coord.push({ x1: x, y1: cross[i].y, x2: x, y2 })
        } else if (i === cross.length) {
          let y2
          let x = cross[0].x

          if (cross[0].x !== cross[1].x) {
            if (cross[0]?.x > cross[4]?.x && sides.d < 2) {
              y2 = cross[0].x === cross[1].x ? cross[2].y : cross[4].y
              x = parseInt((biasAngle[i][2].start.x + ((biasAngle[3][2].start.x - biasAngle[i][2].start.x).toFixed(0)) / 2))
            } else {
              y2 = cross[i - 2].y !== cross[i - 3].y ? cross[i - 2].y : defaultSvgParams.height
              x = cross[0].x
            }

            coord.push({ x1: x, y1: cross[0].y, x2: x, y2 })
          }
        }
      }
    } else if (quantityAngleZones[i] === 0) {
      const areTwoZeroAngleZones = quantityAngleZones.filter((q) => q === 0).length === 2
      const sideStartPointX = (biasAngle[i][2].start.x).toFixed(0)
      let x = sideStartPointX - (biasAngle[i][1].ratio).toFixed(0)

      if (Vector.isVerticalUp(vector)) {
        if (areTwoZeroAngleZones) {
          const lastCoord = coord[i - 1]
          if (lastCoord) {
            coord.push({ x1: x, y1: lastCoord.y2, x2: x, y2: cross[i + 2].y })
          }
        } else {
          let y1 = coord[i - 1].y2
          let y2 = defaultSvgParams.height
          const upperContraAngleIndex = 7

          if (x > cross[0].x && biasAngle[1][1].correction) {
            y1 = coord[0].y1

            if (sides.d < 2 && x > cross[1].x) {
              x = parseInt((biasAngle[i][2].start.x - ((biasAngle[i][1].ratio / biasAngle[i][0]).toFixed(0)) / 2))
              y2 = x < cross[upperContraAngleIndex].x ? cross[upperContraAngleIndex].y : cross[upperContraAngleIndex - 1].y
            } else if (x <= cross[upperContraAngleIndex].x) {
              x = cross[upperContraAngleIndex].x
            }
          } else if (x <= cross[upperContraAngleIndex].x) {
            x = cross[upperContraAngleIndex].x
          } else if (x < cross[0].x && sides.d < 1.8) {
            x = coord[0].x1
          }
          coord.push({ x1: x, y1, x2: x, y2 })
        }
      }
      if (Vector.isVerticalDown(vector)) {
        const lastCoord = coord[i - 1]
        x = parseInt((biasAngle[i][1].ratio).toFixed(0)) + parseInt(sideStartPointX)

        if (figure === X_SHAPE && x > cross[1].x) {
          x = cross[1].x
        }

        if ((areTwoZeroAngleZones || x < coord[3].x2) && lastCoord) {
          coord.push({ x1: x, y1: lastCoord.y2, x2: x, y2: cross[i + 2].y })
        }
      }
    }
  }

  const corrected = correctCoordinatesIfThereIsIntersection(coord)
  const withoutPoints = removePoints(corrected)
  const withoutVerticalCrossing = removeVerticalLinesCrossingSides(withoutPoints, initialCoords)

  return removeHorizontalOverlays(withoutVerticalCrossing)
}

function removePoints(arr) {
  return arr.filter((p) => !(p.x1 === p.x2 && p.y1 === p.y2))
}

function removeHorizontalOverlays (dashedLines) {
  const overlays = {}
  const withoutOverlays = dashedLines.map((l) => {
    if (l.y1 === l.y2) {
      overlays[l.y1] = overlays[l.y1] ? [...overlays[l.y1], l] : [l]
      return null
    }
    return l
  }).filter((l) => l)

  const filteredOverlays = Object.entries(overlays).map(([key, value]) => {
    if (value.length > 1) {
      if (value.length === 2 && !defineLineIntersection(
        value[0].x1, value[0].y1, value[0].x2, value[0].y2,
        value[1].x1, value[1].y1, value[1].x2, value[1].y2)
      ) {
        return [...value]
      }

      const x1 = Math.min(...value.map(i => i.x1), ...value.map(i => i.x2))
      const x2 = Math.max(...value.map(i => i.x1), ...value.map(i => i.x2))

      return { x1, x2, y1: Number(key), y2: Number(key) }
    } else {
      return value[0]
    }
  })

  return [ ...withoutOverlays, ...filteredOverlays.flat() ]
}

function removeVerticalLinesCrossingSides(dashedLines, sides) {
  const verticalSides = sides.map((s, i, array) => {
    const nextIndex = array[i + 1] ? i + 1 : 0

    return {
      x1: parseInt(s.x.toFixed(1)),
      y1: parseInt(s.y.toFixed(1)),
      x2: parseInt(array[nextIndex].x.toFixed(1)),
      y2: parseInt(array[nextIndex].y.toFixed(1))
    }
  }).filter((s) => s.x1 === s.x2)

  return dashedLines
    .filter((s) => {
      const line = {
        x1: parseInt(s.x1.toFixed(1)),
        y1: parseInt(s.y1.toFixed(1)),
        x2: parseInt(s.x2.toFixed(1)),
        y2: parseInt(s.y2.toFixed(1))
      }

      return !verticalSides.find((vs) => vs.x1 === line.x1 && line.x1 === line.x2)
    })
}

function correctCoordinatesIfThereIsIntersection(coord) {
  for (let i = 0; i < coord.length; i++) {
    const line = coord[i]
    let currentVector = new Vector(new Point({ x: line.x1, y: line.y1 }), new Point({ x: line.x2, y: line.y2 }))

    const cases = (Vector.isVerticalUp(currentVector) && line.y2 === defaultSvgParams.height) ||
      (Vector.isHorizontalRight(currentVector) && line.x2 === defaultSvgParams.width) ||
      (Vector.isVerticalDown(currentVector) && line.y2 === 0) ||
      (Vector.isHorizontalLeft(currentVector) && line.x2 === 0)

    const matches = []

    if (cases) {
      coord.forEach((l, index) => {
        if (index !== i) {
          const match = defineLineIntersection(l.x1, l.y1, l.x2, l.y2, line.x1, line.y1, line.x2, line.y2)

          if (match && Vector.isVerticalUp(currentVector)) {
            matches.push(match)
          }
          if (match && Vector.isHorizontalRight(currentVector)) {
            matches.push(match)
          }
          if (match && Vector.isVerticalDown(currentVector)) {
            matches.push(match)
          }
          if (match && Vector.isHorizontalLeft(currentVector)) {
            matches.push(match)
          }
        }
      })
    }
    if (matches.length) {
      if (Vector.isVerticalUp(currentVector)) {
        const yMatches = matches.map((m) => m.y).filter((m) => m !== line.y1 && m > line.y1)
        line.y2 = Math.min(...yMatches)
      }
      if (Vector.isHorizontalLeft(currentVector)) {
        const yMatches = matches.map((m) => m.x).filter((m) => m !== line.x1)
        line.x2 = Math.max(...yMatches)
      }
      if (Vector.isVerticalDown(currentVector)) {
        const yMatches = matches.map((m) => m.y).filter((m) => m !== line.y1 && m < line.y1)
        line.y2 = Math.max(...yMatches)
      }
      if (Vector.isHorizontalRight(currentVector)) {
        const xMatches = matches.map((m) => m.x).filter((m) => m !== line.x1)
        line.x2 = Math.min(...xMatches)
      }
    }
  }

  return coord
}

function defineLineIntersection (x1, y1, x2, y2, x3, y3, x4, y4) {
  const denom = (y4 - y3) * (x2 - x1) - (x4 - x3) * (y2 - y1)

  if (denom === 0) {
    return null
  }

  const ua = ((x4 - x3) * (y1 - y3) - (y4 - y3) * (x1 - x3)) / denom

  return {
    x: x1 + ua * (x2 - x1),
    y: y1 + ua * (y2 - y1)
  }
}

// получение координат для ширины угловой зоны (строим одновременно две ширины для одного угла)
export function getAnglesCoordinates(coords, biasSide, biasAngle, withRolls = null, withCorrention = false) {
  let path = []
  let angleCoordinates = []

  const n = coords.length
  for (let i = 0; i < n; i++) {
    const current = new Point(coords[i])
    const prev = new Point(coords[i === 0 ? n - 1 : i - 1])
    const next = new Point(coords[i === n - 1 ? 0 : i + 1])

    const vectorPrev = new Vector(prev, current)
    const vectorNext = new Vector(current, next)

    if (Vector.ifRightCornerVectors(vectorPrev, vectorNext)) {
      let dOffset1 = withRolls
        ? vectorPrev.getNegativeOffsetCoordinates(getMaxWithCorrection(biasAngle[i === 0 ? n - 1 : i - 1], biasSide[i])[1].ratio)
        : vectorPrev.getNegativeOffsetCoordinates(getMax(biasAngle[i === 0 ? n - 1 : i - 1][0], biasSide[i][0]))

      let dOffset2 = withRolls
        ? vectorNext.getPositiveOffsetCoordinates(getMaxWithCorrection(biasAngle[i], biasSide[i === 0 ? n - 1 : i - 1])[1].ratio)
        : vectorNext.getPositiveOffsetCoordinates(getMax(biasAngle[i][0], biasSide[i === 0 ? n - 1 : i - 1][0]))

      let lOffset1 = withRolls
        ? withCorrention
          ? vectorPrev.getClockWiseOffsetCoordinates(getMaxWithCorrection(biasAngle[i], biasSide[i === 0 ? n - 1 : i - 1])[1].ratio)
          : vectorPrev.getClockWiseOffsetCoordinates(biasSide[i === 0 ? n - 1 : i - 1][1].ratio)
        : vectorPrev.getClockWiseOffsetCoordinates(biasSide[i === 0 ? n - 1 : i - 1][0])

      let lOffset2 = withRolls
        ? withCorrention
          ? vectorNext.getClockWiseOffsetCoordinates(getMaxWithCorrection(biasAngle[i === 0 ? n - 1 : i - 1], biasSide[i])[1].ratio)
          : vectorNext.getClockWiseOffsetCoordinates(biasSide[i][1].ratio)
        : vectorNext.getClockWiseOffsetCoordinates(biasSide[i][0])

      const sumOffset1 = Vector.sumOffset(dOffset1, lOffset1)
      const sumOffset2 = Vector.sumOffset(dOffset2, lOffset2)

      const arrowStart1 = current.plus(dOffset1)
      const arrowEnd1 = current.plus(sumOffset1)

      const arrowStart2 = current.plus(dOffset2)
      const arrowEnd2 = current.plus(sumOffset2)

      angleCoordinates[i] = [arrowStart1, arrowEnd1, arrowStart2, arrowEnd2]
      path.push(drawZoneArrow(arrowStart1, arrowEnd1, arrowStart2, arrowEnd2))
    }
  }
  return { path: path.join(' '), angleCoordinates }
}

export function getArrowsCoordinates(coords, biasSide, biasAngle, withRolls = null) {
  let arrowsPath = []
  const n = coords.length
  const arrowOffset = defaultSvgParams.biasArrowsOffset
  const textOffset = defaultSvgParams.biasSideTextOffset

  for (let i = 0; i < n; i++) {
    const current = new Point(coords[i])
    const prev = new Point(coords[i === 0 ? n - 1 : i - 1])
    const next = new Point(coords[i === n - 1 ? 0 : i + 1])

    const vectorPrev = new Vector(prev, current)
    const vectorNext = new Vector(current, next)

    if (Vector.ifRightCornerVectors(vectorPrev, vectorNext)) {
      let maxSide1 = withRolls
        ? getMaxWithCorrection(biasAngle[i === 0 ? n - 1 : i - 1], biasSide[i])
        : getMaxArray(biasAngle[i === 0 ? n - 1 : i - 1], biasSide[i])

      let maxSide2 = withRolls
        ? getMaxWithCorrection(biasAngle[i], biasSide[i === 0 ? n - 1 : i - 1])
        : getMaxArray(biasAngle[i], biasSide[i === 0 ? n - 1 : i - 1])

      let dOffset1
      let dOffset2

      if (withRolls) {
        dOffset1 = vectorPrev.getNegativeOffsetCoordinates(maxSide1[1].ratio)
        dOffset2 = vectorNext.getPositiveOffsetCoordinates(maxSide2[1].ratio)
      } else {
        dOffset1 = vectorPrev.getNegativeOffsetCoordinates(maxSide1[0])
        dOffset2 = vectorNext.getPositiveOffsetCoordinates(maxSide2[0])
      }

      // координаты вектора стрелок
      let aStart1 = current.plus({
        dx: dOffset1.dx + Math.sign(vectorPrev.vy) * arrowOffset,
        dy: dOffset1.dy - Math.sign(vectorPrev.vx) * arrowOffset
      })

      let aEnd1 = current.plus({
        dx: Math.sign(vectorPrev.vy) * arrowOffset,
        dy: -Math.sign(vectorPrev.vx) * arrowOffset
      })

      let aStart2 = current.plus({
        dx: dOffset2.dx + Math.sign(vectorNext.vy) * arrowOffset,
        dy: dOffset2.dy - Math.sign(vectorNext.vx) * arrowOffset
      })

      let aEnd2 = current.plus({
        dx: Math.sign(vectorNext.vy) * arrowOffset,
        dy: -Math.sign(vectorNext.vx) * arrowOffset
      })

      // координаты текста стрелок
      let tdx1 = dOffset1.dx / 2 - Vector.getOffset(vectorNext.vx, textOffset)
      let tdy1 = dOffset1.dy / 2 - Vector.getOffset(vectorNext.vy, textOffset)
      let text1 = Point.getTextCoordinates(aEnd1.x + tdx1, aEnd1.y + tdy1, vectorPrev.vy, true)

      let tdx2 = dOffset2.dx / 2 + Vector.getOffset(vectorPrev.vx, textOffset)
      let tdy2 = dOffset2.dy / 2 + Vector.getOffset(vectorPrev.vy, textOffset)

      let text2 = Point.getTextCoordinates(aEnd2.x + tdx2, aEnd2.y + tdy2, vectorNext.vy, true)

      const arrow1 = drawArrowByCoordinate(aStart1, aEnd1, true)
      const arrow2 = drawArrowByCoordinate(aStart2, aEnd2, true)

      arrowsPath.push(
        { path: arrow1, value: maxSide1, text: text1 },
        { path: arrow2, value: maxSide2, text: text2 }
      )
    }
  }

  return arrowsPath
}

export function getSidesCoordinates(coords, biasSide, withRolls = null) {
  let sidesPath = []
  const n = coords.length
  const { biasSideTextOffset, serif } = defaultSvgParams
  const quantityAnglesZone = getQuantityAngleZones(coords)

  for (let i = 0; i < n; i++) {
    const current = new Point(coords[i])
    const next = new Point(coords[i === n - 1 ? 0 : i + 1])

    const center = Point.getCenterCoordinates(current, next)
    const vector = new Vector(current, next)

    let dBias = withRolls
      ? vector.getClockWiseOffsetCoordinates(biasSide[i][1].ratio)
      : vector.getClockWiseOffsetCoordinates(biasSide[i][0])

    let dSerif = vector.getClockWiseOffsetCoordinates(serif)

    let tOffset = withRolls
      ? vector.getClockWiseOffsetCoordinates(biasSide[i][1].ratio + serif + biasSideTextOffset)
      : vector.getClockWiseOffsetCoordinates(biasSide[i][0] + serif + biasSideTextOffset)

    const c1 = new Point({
      x: center.x + dBias.dx,
      y: center.y + dBias.dy
    })

    const sidePath = {
      path: drawSideVector(center, c1, dSerif),
      value: biasSide[i],
      text: Point.getTextCoordinates(center.x + tOffset.dx, center.y + tOffset.dy, vector.vy, false)
    }

    if (withRolls) {
      const cases = (biasSide[i][0] < (biasSide[i][1].realSide / 2) || (!biasSide[i][1].half && quantityAnglesZone[i] === 0))

      if (cases) {
        sidesPath.push(sidePath)
      }
    } else {
      sidesPath.push(sidePath)
    }
  }
  return sidesPath
}

export function getSidesCoordinatesWithCorrection(coords, biasSide, biasAngle) {
  let sidesPath = []
  const n = coords.length
  const { biasSideTextOffset, serif } = defaultSvgParams
  const quantityAnglesZone = getQuantityAngleZones(coords)

  for (let i = 0; i < n; i++) {
    const current = new Point(coords[i])
    const nextIndex = i === (n - 1) ? 0 : i + 1
    const prevIndex = i === 0 ? n - 1 : i - 1
    const next = new Point(coords[nextIndex])

    const center = Point.getCenterCoordinates(current, next)
    const vector = new Vector(current, next)

    let minBetweenPrevAndCurrent
    let minAngle

    if (i !== n - 1) {
      minBetweenPrevAndCurrent = getMinWithCorrection(biasAngle[prevIndex], biasAngle[i])
      minAngle = getMinWithCorrection(minBetweenPrevAndCurrent, biasAngle[nextIndex])
    } else {
      const angleForCompare = biasAngle[i][0] !== biasAngle[prevIndex][0] ? biasAngle[i] : biasAngle[nextIndex]
      const sideOrAngle = biasAngle[i - 1][1].correction ? biasAngle[prevIndex] : biasSide[i]
      minAngle = getMinWithCorrection(angleForCompare, sideOrAngle)
    }

    let dBias = vector.getClockWiseOffsetCoordinates(minAngle[1]?.ratio)
    let dSerif = vector.getClockWiseOffsetCoordinates(serif)
    let tOffset = vector.getClockWiseOffsetCoordinates(minAngle[1].ratio + serif + biasSideTextOffset)

    const c1 = new Point({
      x: center.x + dBias.dx,
      y: center.y + dBias.dy
    })

    const cases = (biasAngle[i][1].correction === null && quantityAnglesZone[i] === 2) ||
      (!biasAngle[i][1].half && quantityAnglesZone[i] === 0 && Object.values(biasSide).length === MAX_SIDES_QUANTITY_IN_XSHAPE)

    if (cases) {
      sidesPath.push({
        path: drawSideVector(center, c1, dSerif),
        value: minAngle,
        text: Point.getTextCoordinates(center.x + tOffset.dx, center.y + tOffset.dy, vector.vy, false)
      })
    }
  }
  return sidesPath
}

export function getLettersCoordinates(coords, angles) {
  const n = coords.length
  const lettersC = []
  const lettersD = []
  const zoneLetterOffset = defaultSvgParams.zoneLetterOffset
  const { c, d } = zoneLetters
  const quantityAnglesZone = getQuantityAngleZones(coords)

  for (let i = 0; i < n; i++) {
    let current = new Point(coords[i])
    let next = new Point(coords[i === n - 1 ? 0 : i + 1])
    const prev = new Point(coords[i === 0 ? n - 1 : i - 1])

    const vectorPrev = new Vector(prev, current)
    const vectorNext = new Vector(current, next)

    // получение координаты середины плоскости
    const center = Point.getCenterCoordinates(current, next)

    // получение смещения в зависимости от координаты
    const dRight = vectorNext.getCounterClockWiseOffsetCoordinates(zoneLetterOffset)

    const cases = (angles[i][0] > (angles[i][1].realSide / 2) && quantityAnglesZone[i] === 1) ||
      ((angles[i][1].correction !== null && quantityAnglesZone[i] === 2))

    if (!cases) {
      lettersD.push({
        x: center.x + dRight.dx,
        y: center.y + dRight.dy,
        letter: d
      })
    }

    if (Vector.ifRightCornerVectors(vectorPrev, vectorNext)) {
      let dx =
        Vector.getOffset(vectorPrev.vx, zoneLetterOffset) +
        Vector.getOffset(vectorPrev.vy, zoneLetterOffset)
      let dy =
        Vector.getOffset(vectorPrev.vy, zoneLetterOffset) -
        Vector.getOffset(vectorPrev.vx, zoneLetterOffset)

      lettersC.push({
        x: current.x + dx,
        y: current.y + dy,
        letter: c
      })
    }
  }
  return { lettersC, lettersD }
}

function getOffsetCoordinatesCurrent(sign1, oppositeSign1, sign2, oppositeSign2, isSmall) {
  const offsetH = horizontalArrowOffset(isSmall)
  const offsetV = verticalArrowOffset(isSmall)
  return {
    dx: sign1 !== 0 ? sign1 * offsetH.x : oppositeSign1 * offsetV.x,
    dy: sign2 !== 0 ? sign2 * offsetV.y : oppositeSign2 * offsetH.y
  }
}

function getOffsetCoordinatesNext(sign1, oppositeSign1, sign2, oppositeSign2, isSmall) {
  const offsetH = horizontalArrowOffset(isSmall)
  const offsetV = verticalArrowOffset(isSmall)
  return {
    dx: sign1 !== 0 ? -sign1 * offsetH.x : oppositeSign1 * offsetV.x,
    dy: sign2 !== 0 ? -sign2 * offsetV.y : oppositeSign2 * offsetH.y
  }
}

const horizontalArrowOffset = isSmall => {
  return {
    x: isSmall ? 4 : 6,
    y: isSmall ? 3 : 5
  }
}

const verticalArrowOffset = isSmall => {
  return {
    x: isSmall ? 3 : 5,
    y: isSmall ? 4 : 6
  }
}
