export class Point {
  constructor(coordinate) {
    this.x = coordinate.x
    this.y = coordinate.y
  }

  plus(offset) {
    return { x: this.x + offset.dx, y: this.y + offset.dy }
  }

  static areEqual(p1, p2) {
    return p1.x === p2.x && p1.y === p2.y
  }

  static areCollinear(p1, p2, p3) {
    const slope1 = (p2.y - p1.y) / (p2.x - p1.x)
    const slope2 = (p3.y - p2.y) / (p3.x - p2.x)
    return slope1 === slope2
  }

  static getCenterCoordinates(prev, next) {
    return {
      x: (next.x + prev.x) / 2,
      y: (next.y + prev.y) / 2
    }
  }

  static getTextCoordinates(tx, ty, vector, isPositive) {
    return {
      x: tx,
      y: ty,
      rotate: isPositive ? Math.sign(vector) * 90 : -Math.sign(vector) * 90
    }
  }
}
