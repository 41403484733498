export const getDistance = (x1, y1, x2, y2) => Math.max(Math.abs(x2 - x1), Math.abs(y2 - y1))

export const normalizedDist = (dist, scale) => Number((dist / scale).toFixed(2))

export const getMax = (...args) => Math.max(...args)
export const getMin = (...args) => Math.min(...args)

export const getMaxArray = (arr1, arr2) => {
  return arr1[0] > arr2[0] ? arr1 : arr2
}

export const getMaxWithCorrection = (arr1, arr2) => {
  if (arr1[1].correction !== null) return arr1
  if (arr2[1].correction !== null) return arr2

  return arr1[1].ratio > arr2[1].ratio ? arr1 : arr2
}

export const getMinWithCorrection = (arr1, arr2) => {
  if (arr1[1].correction !== null) return arr1
  if (arr2[1].correction !== null) return arr2

  return arr1[1].ratio < arr2[1].ratio ? arr1 : arr2
}
