export class Vector {
  constructor(source, dest) {
    this.vx = dest.x - source.x
    this.vy = dest.y - source.y
  }

  get vector() {
    return {
      vx: this.vx,
      vy: this.vy
    }
  }

  getClockWiseOffsetCoordinates(offset) {
    return {
      dx: -Math.sign(this.vector.vy) * offset,
      dy: Math.sign(this.vector.vx) * offset
    }
  }

  getCounterClockWiseOffsetCoordinates(offset) {
    return {
      dx: Math.sign(this.vector.vy) * offset,
      dy: -Math.sign(this.vector.vx) * offset
    }
  }

  getPositiveOffsetCoordinates(offset) {
    return {
      dx: Math.sign(this.vector.vx) * offset,
      dy: Math.sign(this.vector.vy) * offset
    }
  }

  getNegativeOffsetCoordinates(offset) {
    return {
      dx: -Math.sign(this.vector.vx) * offset,
      dy: -Math.sign(this.vector.vy) * offset
    }
  }

  static getOffset(vectorCoordinate, offset) {
    return Math.sign(vectorCoordinate) * offset
  }

  static sumOffset(offset1, offset2) {
    return {
      dx: offset1.dx + offset2.dx,
      dy: offset1.dy + offset2.dy
    }
  }

  static ifRightCornerVectors(vectorPrev, vectorNext) {
    return (
      Vector.getOffset(vectorPrev.vx, 1) === Vector.getOffset(vectorNext.vy, 1) &&
      Vector.getOffset(vectorPrev.vy, 1) === -Vector.getOffset(vectorNext.vx, 1)
    )
  }

  static isVerticalUp(vector) {
    return vector.vx === 0 && vector.vy > 0
  }

  static isVerticalDown(vector) {
    return vector.vx === 0 && vector.vy < 0
  }

  static isHorizontalRight(vector) {
    return vector.vx > 0 && vector.vy === 0
  }

  static isHorizontalLeft(vector) {
    return vector.vx < 0 && vector.vy === 0
  }
}
